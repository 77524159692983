import appConstants from '@/utils/constants/app';
import config from '@/config';
import LocalStorage from './LocalStorage';
import Logger from './Logger';
import mixpanel from 'mixpanel-browser';

let isInitialized = false;

function init() {
  if (!isInitialized) {
    Logger.debug('Initializing mixpanel');
    mixpanel.init(config.mixpanel.token);
    isInitialized = true;
  }
}

function identify(id: string, isNewUser?: boolean) {
  if (!isInitialized) {
    return;
  }
  if (isNewUser) {
    mixpanel.alias(id);
  }
  mixpanel.identify(id);
}

function track(event: string, data?: object) {
  if (!isInitialized) {
    return;
  }
  mixpanel.track(event, {
    Day: new Date().getDay(),
    'Sent from': appConstants.APP_NAME,
    Time: new Date().toTimeString().slice(0, 2),
    ...data,
  });
}

function signIn(user: { id: string }) {
  if (!isInitialized) {
    return;
  }
  if (user && user.id) {
    identify(user.id);
    if (LocalStorage.isAvailable()) {
      const flag = LocalStorage.getItem('mixpanel_event_sent');
      const setTime = new Date(
        LocalStorage.getItem('mixpanel_event_time') || new Date()
      );
      const thirtyMinutes = 60 * 30 * 1000;
      if (
        flag !== 'true' ||
        new Date().getTime() - setTime.getTime() > thirtyMinutes
      ) {
        track('Login');
        LocalStorage.setItem('mixpanel_event_sent', true);
        LocalStorage.setItem('mixpanel_event_time', new Date());
      }
    } else {
      track('Login');
    }
  }
}

function signOut() {
  if (!isInitialized) {
    return;
  }
  track('User Logout');
  mixpanel.reset();
  LocalStorage.setItem('mixpanel_event_sent', false);
}

function setSuperProperties(data: object) {
  if (!isInitialized) {
    return;
  }
  mixpanel.register(data);
}

function setPeopleProperties(data: object) {
  if (!isInitialized) {
    return;
  }
  mixpanel.people.set(data);
}

const Mixpanel = {
  identify,
  init,
  setPeopleProperties,
  setSuperProperties,
  signIn,
  signOut,
  track,
};

export default Mixpanel;
