import Analytics from './Analytics';
import appConstants from '@/utils/constants/app';
import config from '@/config';
import Http from './Http';
import LocalStorage from './LocalStorage';
import Logger from '@/services/Logger';
import { notifyAPIError } from '@/services/ErrorMonitoring';
import routeConstants from '@/utils/constants/routes';
import Router from 'next/router';
import axios, { AxiosError } from 'axios';
interface LoginSession {
  displayName?: string;
  email: string;
  id: string;
  photoURL?: string;
  providerId: string;
}

interface LoginProps {
  email?: string;
  password?: string;
  provider: 'password' | 'google.com';
  authCode?: string;
}

interface SignUpProps {
  attributionData?: {
    installSource?: string;
    campaign?: string;
    medium?: string;
    content?: string;
    feature?: string;
  };
  email?: string;
  password?: string;
  provider: 'password' | 'google.com';
  authCode?: string;
  givenName?: string;
}

interface AuthData {
  id: string;
  email: string;
  displayName: string;
  photoURL: string;
  providerId: string;
}

async function signUp({
  attributionData = {},
  authCode,
  email,
  givenName,
  password,
  provider,
}: SignUpProps) {
  try {
    const data = {
      attributionData: {
        ...attributionData,
        sourcePlatform: appConstants.APP_NAME,
      },
      authCode,
      createdAt: new Date().toISOString(),
      email,
      givenName,
      password,
      provider,
      signUpSource: appConstants.APP_NAME,
      timezone:
        (Intl && Intl.DateTimeFormat().resolvedOptions().timeZone) || null,
    };
    const options = {
      data,
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
      method: 'POST',
      url: `${config.api.auraServices}/users/signup`,
    };
    const response = await Http.handle(options);
    if (response && response.status === 200 && response.data) {
      Logger.debug('User signup', { data: response.data });
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error as AxiosError, {
      message: 'Error while signup',
    });
    return null;
  }
}

async function login({ authCode, email, password, provider }: LoginProps) {
  try {
    const data = {
      authCode,
      email,
      password,
      provider,
    };
    const options = {
      data,
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
      method: 'POST',
      url: `${config.api.businessWeb}/users/authenticate`,
    };
    const response = await Http.handle(options);
    if (response && response.status === 200 && response.data) {
      handleAuthSession(response.data);
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error as AxiosError, {
      message: 'Error while authentication',
    });
    return null;
  }
}

async function signUpOrLogin({
  attributionData = {},
  authCode,
  email,
  givenName,
  password,
  provider,
}: SignUpProps) {
  try {
    const data = {
      attributionData: {
        ...attributionData,
        sourcePlatform: appConstants.APP_NAME,
      },
      authCode,
      createdAt: new Date().toISOString(),
      email,
      givenName,
      password,
      provider,
      signUpSource: appConstants.APP_NAME,
      timezone:
        (Intl && Intl.DateTimeFormat().resolvedOptions().timeZone) || null,
    };
    const options = {
      data,
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
      method: 'POST',
      url: `${config.api.businessWeb}/users/signupOrLogin`,
    };
    const response = await Http.handle(options);
    if (response && response.status === 200 && response.data) {
      handleAuthSession(response.data, response.data.isNewUser);
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error as AxiosError, {
      message: 'Error while signup',
    });
    return null;
  }
}

function handleAuthSession(authData: AuthData, isNewUser?: boolean) {
  if (!authData) return;
  Logger.debug('User authentication', { authData });
  const { displayName: givenName, email, id, providerId: provider } = authData;
  const user = { email, givenName, id, provider };
  Analytics.identifyUser(user, isNewUser);
  if (isNewUser) {
    Analytics.setPeopleProperties({
      $created: new Date(),
      $email: email,
      $first_name: givenName,
      'Attribution Source Platform': appConstants.APP_NAME,
      Provider: provider,
      UserID: id,
    });
    Analytics.track(`Sign up login`, {
      Provider: provider,
      Source: appConstants.APP_NAME,
      UserEmail: email,
    });
  }
  Analytics.signIn(user);
  setCurrentLoginSession(authData);
}

function setCurrentLoginSession(loginSession: LoginSession) {
  LocalStorage.setItem(LocalStorage.KEYS.LOGIN_SESSION, loginSession);
}

function getCurrentLoginSession() {
  return LocalStorage.getItem(LocalStorage.KEYS.LOGIN_SESSION);
}

async function logout({ redirectToLogin }: { redirectToLogin?: boolean }) {
  Logger.debug('Logging out user', { redirectToLogin });
  try {
    const options = {
      json: true,
      method: 'POST',
      url: `${config.api.businessWeb}/users/logout`,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      Logger.debug('Logged out user', { data: response.data });
      LocalStorage.removeItem(LocalStorage.KEYS.LOGIN_SESSION);
      Analytics.resetUser();

      if (redirectToLogin) Router.replace(routeConstants.PAGE_LOGIN);
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error as AxiosError, {
      message: 'Error while email and password authentication',
    });
    return null;
  }
}

export default {
  getCurrentLoginSession,
  login,
  logout,
  setCurrentLoginSession,
  signUp,
  signUpOrLogin,
};
