import appConstants from '@/utils/constants/app';
import config from '@/config';

const isClient = () => {
  return typeof window === 'object';
};

function isProdMode() {
  return config.mode === appConstants.MODE_PRODUCTION;
}

function getCookie(cname: string) {
  const name = `${cname}=`;
  if (typeof document === 'undefined') {
    return null;
  }
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

function generateQueryPath(pathname: string, query: any) {
  const path = `/${pathname}`;
  if (!query || typeof query !== 'object') {
    return path;
  }
  const queryParams = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (value) {
      queryParams.set(key, value);
    }
  });
  const queryString = queryParams.toString();
  if (queryString && queryString.length) {
    return `${path}?${queryParams.toString()}`;
  }
  return path;
}

function generateExternalUrlQueryPath(pathname: string, query: any) {
  const path = `${pathname}`;
  if (!query || typeof query !== 'object') {
    return path;
  }
  const queryParams = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (value) {
      queryParams.set(key, value);
    }
  });
  const queryString = queryParams.toString();
  if (queryString && queryString.length) {
    return `${path}?${queryParams.toString()}`;
  }
  return path;
}

function formatSecondsAsTime(currentTime: number | undefined) {
  if (typeof currentTime !== 'number') return '00:00';
  const hr = Math.floor(currentTime / 3600);
  const min = Math.floor((currentTime - hr * 3600) / 60);
  const sec = Math.floor(currentTime - hr * 3600 - min * 60);
  let formattedMin = `${min}`;
  let formattedSec = `${sec}`;
  if (min < 10) {
    formattedMin = `0${min}`;
  }
  if (sec < 10) {
    formattedSec = `0${sec}`;
  }

  if (hr > 0) {
    return `${hr}:${formattedMin}:${formattedSec}`;
  }
  return `${formattedMin}:${formattedSec}`;
}

function isNotificationSupported() {
  return 'Notification' in window;
}

function isObject(object: any) {
  return object != null && typeof object === 'object';
}

function deepObjectEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i];
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepObjectEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function formatNumber(num: string) {
  const number = parseInt(num, 10);
  if (Number.isNaN(number)) return 0;
  return number.toLocaleString();
}

function formatAmount(num: string) {
  const number = parseFloat(num);
  if (Number.isNaN(number)) return `$ 0`;
  return `$ ${number.toLocaleString()}`;
}

function convertToDollar(cent: number) {
  if (!cent) return 0;
  return cent / 100;
}

function roundToNearest(number: number, place: number) {
  // If place is not power of 10 return
  if (place % 10) return number;
  return Math.ceil(number / place) * place;
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export {
  isClient,
  isProdMode,
  isNotificationSupported,
  getCookie,
  generateQueryPath,
  generateExternalUrlQueryPath,
  formatSecondsAsTime,
  deepObjectEqual,
  formatNumber,
  formatAmount,
  convertToDollar,
  roundToNearest,
  sleep,
};
