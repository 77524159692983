import Analytics from '@/services/Analytics';
import DefaultHeadTags from '@AppComponents/DefaultHeadTags';
import Image from 'next/image';
import NProgress from 'nprogress';
import Router from 'next/router';
import styles from './styles.module.scss';
import React, { useEffect } from 'react';

// NProgress
NProgress.configure({ showSpinner: false });

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();

  window.scrollTo(0, 0);
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

interface Props {
  children: React.ReactNode;
  hideBackground?: boolean;
  hideLogo?: boolean;
  navBackground?: boolean;
}

export default function BaseLayout({
  children,
  hideBackground,
  hideLogo,
  navBackground,
}: Props): JSX.Element {
  useEffect(() => {
    Analytics.init();
  }, []);

  return (
    <div>
      <DefaultHeadTags />
      {!hideBackground && (
        <>
          <Image
            alt="aura"
            className={styles.darkmodebackground}
            fill
            src="/images/background.png"
          />
          {!hideLogo && (
            <div className={styles.auraContainer}>
              <Image
                alt="aura"
                className={styles.auraLogo}
                height={45}
                src="/images/auraLogo.png"
                width={45}
              />
              <div className={styles.auraText}>Aura</div>
            </div>
          )}
        </>
      )}
      {navBackground && (
        <div id="app-background">
          <Image alt="" fill src="/images/webBackground.png" />
        </div>
      )}
      {children}
    </div>
  );
}
