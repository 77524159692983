import Logger from './Logger';

const isLocalStorageAvailable = (): boolean => {
  try {
    const storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

function setItem(key: string, value: any) {
  if (!isLocalStorageAvailable()) {
    Logger.warn('Local storage is not available', {
      key,
      method: 'setItem',
      value,
    });
    return;
  }
  if (!key) {
    return;
  }
  Logger.debug('storing to local', { key, value });
  window.localStorage.setItem(key, JSON.stringify(value));
}

function removeItem(key: string) {
  if (!isLocalStorageAvailable()) {
    Logger.warn('Local storage is not available', {
      key,
      method: 'removeItem',
    });
    return;
  }
  if (!key) {
    return;
  }
  window.localStorage.removeItem(key);
}

function getItem(key: string): any {
  if (!isLocalStorageAvailable()) {
    Logger.warn('Local storage is not available', {
      key,
      method: 'getItem',
    });
    return null;
  }
  if (!key) {
    return null;
  }
  let value = window.localStorage.getItem(key);
  if (value) {
    value = JSON.parse(value);
  }
  Logger.debug('fetched from local', { key, value });
  return value;
}

const KEYS = {
  LAST_LOGIN: 'LAST_LOGIN',
  LOGIN_EVENT_SENT: 'LOGIN_EVENT_SENT',
  LOGIN_SESSION: 'LOGIN_SESSION',
  WELCOME_MESSAGE_CLOSED: 'WELCOME_MESSAGE_CLOSED',
};

const LocalStorage = {
  KEYS,
  getItem,
  isAvailable: isLocalStorageAvailable,
  removeItem,
  setItem,
};

export default LocalStorage;
