import Logger from './Logger';
import axios, { AxiosRequestConfig } from 'axios';

/**
 * @method handle
 * @param {Object} options
 * @returns {Object} response data
 * @description handle the request
 * @async
 */
async function handle(options: AxiosRequestConfig) {
  const { url } = options;
  const meta = {
    component: 'httpConnector',
    method: 'handle',
    url,
  };
  const request = options;
  Logger.debug(`Handling request`, { ...meta, request });
  const response = await axios({ ...request, withCredentials: true });
  Logger.debug(`Received a response for the HTTP call`, meta);
  return response;
}

export default { handle };
