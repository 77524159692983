const routeConstants = {
  // Pages
  PAGE_BILLING: '/billing',
  PAGE_DASHBOARD: '/dashboard',
  PAGE_LOGIN: '/login',
  PAGE_MEMBERS: '/members',
  PAGE_NAMES: {
    '/billing': 'Billing',
    '/dashboard': 'Dashboard',
    '/login': 'Login',
    '/members': 'Members',
    '/redeem/[businessId]': 'User Redemption',
    '/restricted': 'Restricted',
    '/settings': 'Settings',
  },
  PAGE_REDEEM: '/redeem',
  PAGE_RESTRICTED: '/restricted',
  PAGE_SETTINGS: '/settings',
};

export default routeConstants;
