import appConstants from '@Constants/app';
import BugsnagClient from '@Services/Bugsnag';
import BusinessUserType from '@/utils/types/businessUser.type';
import LocalStorage from '@Services/LocalStorage';
import Logger from '@Services/Logger';
import Mixpanel from '@Services/Mixpanel';

type User = {
  id: string;
  givenName: string;
  email: string;
};

function init() {
  Mixpanel.init();
}

function initMixpanel() {
  Mixpanel.init();
}

function identifyUser(user?: User, isNewUser?: boolean) {
  if (user && user.id) {
    const { email, givenName, id } = user;
    Mixpanel.identify(user.id, isNewUser);
    BugsnagClient.setUser(id, email, givenName);
    Logger.addUserData({ email, givenName, id });
    const superProperties = {
      Name: givenName,
      UserID: id,
    };
    setSuperProperties(superProperties);
  }
}

function identifyBusinessUser(businessUser: BusinessUserType) {
  if (businessUser && businessUser.id) {
    const { businessId, id, name, role } = businessUser;
    Logger.addBusinessUserData(businessUser);
    const properties = {
      'Business User Name': name,
      'Business User Role': role,
      BusinessId: businessId,
      BusinessUserId: id,
    };
    setPeopleProperties(properties);
    setSuperProperties(properties);
  }
}

function signIn(user: User) {
  if (!user || !user.id) {
    return;
  }
  identifyUser(user);
  if (LocalStorage.isAvailable()) {
    const flag = LocalStorage.getItem(LocalStorage.KEYS.LOGIN_EVENT_SENT);
    const setTime = new Date(
      LocalStorage.getItem(LocalStorage.KEYS.LAST_LOGIN)
    );
    const thirtyMinutes = 60 * 30 * 1000;
    if (!flag || new Date().getTime() - setTime.getTime() > thirtyMinutes) {
      track('Login');
      LocalStorage.setItem(LocalStorage.KEYS.LOGIN_EVENT_SENT, true);
      LocalStorage.setItem(
        LocalStorage.KEYS.LAST_LOGIN,
        new Date().toISOString()
      );
    }
  } else {
    track('Login');
  }
}

function setSuperProperties(data: object) {
  Mixpanel.setSuperProperties(data);
}

function setPeopleProperties(data: object) {
  Mixpanel.setPeopleProperties(data);
}

function track(event: string, data?: object) {
  Mixpanel.track(event, {
    Day: new Date().getDay(),
    Platform: appConstants.APP_NAME,
    'Sent from': appConstants.APP_NAME,
    Time: new Date().toTimeString().slice(0, 2),
    ...data,
  });
  BugsnagClient.leaveBreadcrumb(event, data);
  Logger.debug(event, data);
}

function resetUser() {
  Mixpanel.signOut();
  BugsnagClient.setUser(undefined, undefined, undefined);
  if (LocalStorage.isAvailable()) {
    LocalStorage.removeItem(LocalStorage.KEYS.LOGIN_EVENT_SENT);
    LocalStorage.removeItem(LocalStorage.KEYS.LAST_LOGIN);
  }
}

export default {
  identifyBusinessUser,
  identifyUser,
  init,
  initMixpanel,
  resetUser,
  setPeopleProperties,
  setSuperProperties,
  signIn,
  track,
};
