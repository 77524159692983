/**
 * @module Logger
 * @description Service for the application logging
 *    Levels configured:
 *    { crit: 1, error: 2, warn: 3, info: 4, debug: 5}
 * @example Usage example (with metadata):
 *    Logger.warn(`user ${user} not authorized`, { ip: req.ip, user: req.jwt.user});
 * @copyright Aura Health, Inc.
 */
import axios from 'axios';
import BusinessUserType from '@/utils/types/businessUser.type';
import config from '@/config';
import { v4 as uuidv4 } from 'uuid';
import { isClient, isProdMode } from '@/utils';

const {
  logger: { level },
  service: { name: service, version },
} = config;

/**
 * Logger
 */
const levels = {
  crit: 1,
  debug: 5,
  error: 2,
  info: 4,
  warn: 3,
};
const stackdriver = {
  crit: 600,
  debug: 100,
  error: 500,
  info: 200,
  warn: 400,
};

class Logger {
  isServerLogger: boolean;
  baseMeta: any;

  constructor() {
    this.isServerLogger = !isClient();
    this.baseMeta = {
      loggerId: uuidv4(),
      serviceContext: {
        service,
        version,
      },
    };
    if (isProdMode() && isClient() && window.navigator) {
      const {
        appCodeName,
        appName,
        appVersion,
        platform,
        product,
        userAgent,
        vendor,
      } = window.navigator;
      this.baseMeta.browser = {
        appCodeName,
        appName,
        appVersion,
        platform,
        product,
        userAgent,
        vendor,
      };
    }
  }

  formatMeta(meta: object, logLevel: keyof typeof stackdriver) {
    const metaData = {
      ...this.baseMeta,
      ...meta,
      severity: stackdriver[logLevel],
    };
    metaData.timestamp = new Date().toISOString();
    return metaData;
  }

  addUserData(userData: {
    id: string;
    role?: string;
    email: string;
    givenName: string;
  }) {
    const { email, givenName, id, role } = userData;
    const { user = {} } = this.baseMeta;
    user.id = id;
    user.role = role;
    user.email = email;
    user.givenName = givenName;
    this.baseMeta.user = user;
  }

  addBusinessUserData(businessUser: BusinessUserType) {
    if (!businessUser) return;
    const { id, name, role } = businessUser;
    const { user = {} } = this.baseMeta;
    user.businessUserId = id;
    user.businessUserName = name;
    user.businessUserRole = role;
    this.baseMeta.user = user;
  }

  log(message: string, meta: object, logLevel: keyof typeof levels) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (levels[logLevel] > levels[level]) return;
    const metaData = this.formatMeta(meta, logLevel);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    // eslint-disable-next-line no-console
    const logger = console[logLevel] || console.error;
    if (this.isServerLogger) {
      logger(JSON.stringify({ message, ...metaData }));
    } else if (!isProdMode()) {
      // No logs on client in production
      logger(message, metaData);
    }
    this.logToServer(message, metaData);
  }

  logToServer(message: string, meta: object) {
    try {
      if (isClient()) {
        axios({
          data: { message, ...meta },
          method: `post`,
          timeout: 11000,
          url: `${config.appDomain}/api/log`,
        });
      }
    } catch (err) {
      // do nothing
    }
  }

  crit(message: { [key: string]: any; stack: string } & string, meta = {}) {
    const logMessage = message.stack || message;
    this.log(logMessage, meta, 'crit');
  }

  error(message: string, meta: object = {}) {
    this.log(message, meta, 'error');
  }

  warn(message: string, meta: object = {}) {
    this.log(message, meta, 'warn');
  }

  info(message: string, meta: object = {}) {
    this.log(message, meta, 'info');
  }

  debug(message: string, meta: object = {}) {
    this.log(message, meta, 'debug');
  }
}

export default new Logger();
