import { AxiosError } from 'axios';
import BugsnagClient from '@Services/Bugsnag';
import Logger from '@Services/Logger';

type ErrorData = {
  message?: string;
  [key: string]: any;
};

function notifyAPIError(error: AxiosError, data: ErrorData = {}) {
  const { message, ...metadata } = data;
  if (message) {
    Logger.warn(message, { error, metadata });
  }
  const handledError = error || new Error(message);
  const headers = error?.response?.headers;
  BugsnagClient.notify(handledError, (event) => {
    if (headers) {
      event.addMetadata('Transaction', {
        transactionId: headers['x-response-id'],
        transactionSlice: headers['x-response-id-slice'],
      });
    }
    event.addMetadata('CUSTOM', { message, ...metadata });
  });
}

function notifyHandledError(error: Error, data: ErrorData = {}) {
  const { message, ...metadata } = data;
  if (message) {
    Logger.warn(message, { error, metadata });
  }
  const handledError = error || new Error(message);
  BugsnagClient.notify(handledError, (event) => {
    event.addMetadata('CUSTOM', { message, ...metadata });
  });
}

export { notifyAPIError, notifyHandledError };
