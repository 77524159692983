import Head from 'next/head';

const DefaultHeadTags = () => (
  <Head>
    <title key="title">Aura - Business Platform</title>

    <meta
      key="description"
      content="Become an Aura business partner. Manage your business subscription and view insights on how your employees use the Aura app."
      name="description"
    />

    <meta
      key="og:title"
      content="Aura - Business Platform"
      property="og:title"
    />

    <meta
      key="og:description"
      content="Become an Aura business partner. Manage your business subscription and view insights on how your employees use the Aura app."
      property="og:description"
    />

    <meta
      key="og:image"
      content={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/images/banner.jpg`}
      property="og:image"
    />

    <meta
      key="twitter:card"
      content="summary_large_image"
      name="twitter:card"
    />

    <meta content="noindex, nofollow" name="robots" />

    <link href="/icons/auraLogo.png" rel="icon" type="image/png" />

    <link href="/icons/auraLogo.png" rel="apple-touch-icon" type="image/png" />
  </Head>
);

export default DefaultHeadTags;
